
.folder.selected, .file.selected {
    background-color: black;
    color: white;
}

.card {
    display: inline-block;
    padding: 6px;
}

.card.selected {
    border: 2px solid;
    padding: 4px;
}